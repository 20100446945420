import React from 'react';
import type {
  ProductCarouselNavigationControl,
  ProductCarouselProduct,
} from '@noths/polaris-client-ribbons-design-system';
import { ProductCarousel } from '@noths/polaris-client-ribbons-design-system';
import { toSentenceCase } from '@noths/polaris-client-utils';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import { useRecommendationsServiceProductData } from 'src/hooks/useRecommendationsServiceProductData/useRecommendationsServiceProductData';
import type { RecommendationsServiceProduct } from 'src/services/recommendations/types';
import type { RecommendedItem } from 'src/types/recommendations';

export const testId = 'ProductCarouselWithRecommendationsServiceDataTestId';

interface ProductCarouselWithRecommendationsServiceDataOnNavigatePayload {
  numberOfItems: number;
  strategy: string;
}

interface ProductCarouselWithRecommendationsServiceDataOnVisiblePayload {
  products: RecommendationsServiceProduct[];
  strategy: string;
}

interface ProductCarouselWithRecommendationsServiceDataOnProductClickPayload {
  index: number;
  numberOfItems: number;
  product: RecommendationsServiceProduct;
  recommendationsTrackingUrl: string;
  strategy: string;
}

export interface ProductCarouselWithRecommendationsServiceDataProps {
  currency: CurrencyCode;
  items?: RecommendedItem[];
  onNavigateCarousel?: (
    swiped: boolean,
    control: ProductCarouselNavigationControl,
    payload: ProductCarouselWithRecommendationsServiceDataOnNavigatePayload,
  ) => void;
  onProductClick?: (
    e: React.MouseEvent<HTMLAnchorElement>,
    payload: ProductCarouselWithRecommendationsServiceDataOnProductClickPayload,
  ) => void;
  onVisible?: (payload: ProductCarouselWithRecommendationsServiceDataOnVisiblePayload) => void;
  placementTitle?: string;
  strategy?: string;
}

const transformRecommendationsServiceProductToProductCarouselProduct = (
  {
    images,
    links,
    new: isNew,
    on_sale: onSale,
    pre_sale_prices,
    prices,
    purchasable: isPurchasable,
    sale_percentage,
    title,
  }: RecommendationsServiceProduct,
  currency: CurrencyCode,
  linkUrl: string,
): ProductCarouselProduct => ({
  title,
  src: images[0]?.href || '',
  href: links[0]?.href || '',
  alt: '',
  isNew,
  onSale,
  isPurchasable,
  price: prices?.find((prices) => prices.currency === currency),
  preSalePrice: pre_sale_prices?.find((price) => price.currency === currency),
  salePercentage: sale_percentage ?? undefined,
  trackingURL: linkUrl,
});

export const ProductCarouselWithRecommendationsServiceData = (
  props: ProductCarouselWithRecommendationsServiceDataProps,
) => {
  const {
    currency,
    items = [],
    onNavigateCarousel,
    onProductClick,
    onVisible,
    placementTitle = '',
    strategy = '',
  } = props;
  const { loaded, products } = useRecommendationsServiceProductData(items);

  return !loaded || products.length > 0 ? (
    <div data-testid={testId}>
      <ProductCarousel
        onNavigation={(swiped, control) => {
          onNavigateCarousel?.(swiped, control, { numberOfItems: products.length, strategy });
        }}
        onProductClick={(evt, { index }) => {
          onProductClick?.(evt, {
            product: products[index].product,
            recommendationsTrackingUrl: products[index].linkURL,
            index,
            numberOfItems: products.length,
            strategy,
          });
        }}
        onVisible={() => {
          const recommendationsServiceProducts = products.map((product) => product.product);
          onVisible?.({ products: recommendationsServiceProducts, strategy });
        }}
        products={
          loaded
            ? products.map((product) =>
                transformRecommendationsServiceProductToProductCarouselProduct(
                  product.product,
                  currency,
                  product.linkURL,
                ),
              )
            : null
        }
        title={toSentenceCase(placementTitle)}
      />
    </div>
  ) : null;
};
